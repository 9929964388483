import { $t } from '@/i18n/index'

// 全部 操作符数据(包含枚举，数字)
export const allOperatorOptions = [
  { label: 'Is', value: 'CONTAINS', icon: 'is', type: 'enum' },
  { label: 'Is not ', value: 'UNCONTAINS', icon: 'is-not', type: 'enum' },
  { label: 'Equal to', value: 'EQ', icon: 'equal-to', type: 'number' },
  { label: 'More than', value: 'GT', icon: 'more-than', type: 'number' },
  { label: 'Less than', value: 'LT', icon: 'less-than', type: 'number' },
  { label: 'Within range', value: 'BETWEEN', icon: 'within-range', type: 'number' },
  { label: 'Outside range', value: 'UNBETWEEN', icon: 'outside-range', type: 'number' },
  { label: 'Is empty', value: 'IS_NULL', icon: 'is-empty', type: 'number' },
  { label: 'Is not empty', value: 'IS_NOT_NULL', icon: 'is-not-empty', type: 'number' }
]

// 全部 操作符数据--仅枚举
export const allEnumOperatorOptions = [
  { label: 'Is', value: 'CONTAINS', icon: 'is', type: 'enum' },
  { label: 'Is not ', value: 'UNCONTAINS', icon: 'is-not', type: 'enum' }
]
// 全部 操作符数据--仅数字
export const allNumOperatorOptions = [
  { label: 'Equal to', value: 'EQ', icon: 'equal-to', type: 'number' },
  { label: 'More than', value: 'GT', icon: 'more-than', type: 'number' },
  { label: 'Less than', value: 'LT', icon: 'less-than', type: 'number' },
  { label: 'Within range', value: 'BETWEEN', icon: 'within-range', type: 'number' },
  { label: 'Outside range', value: 'UNBETWEEN', icon: 'outside-range', type: 'number' },
  { label: 'Is empty', value: 'IS_NULL', icon: 'is-empty', type: 'number' },
  { label: 'Is not empty', value: 'IS_NOT_NULL', icon: 'is-not-empty', type: 'number' }
]

// 操作符
export const operatorMapping: any = {
  UNCONTAINS: 'is-not',
  CONTAINS: 'is',
  EQ: 'equal-to',
  GT: 'more-than',
  LT: 'less-than',
  BETWEEN: 'within-range',
  UNBETWEEN: 'outside-range',
  IS_NULL: 'is-empty',
  IS_NOT_NULL: 'is-not-empty'
}

// MarketMaps的颜色
export const marketMapColorList = [
  '#2D62EB',
  '#E87F05',
  '#26C282',
  '#F54847',
  '#24A7F1',
  '#F9BF3A',
  '#9912B4',
  '#50B8C7',
  '#3A539A',
  '#039374',
  '#F2784B',
  '#C2392C',
  '#3A539A',
  '#6880A1',
  '#2DCB6F',
  '#F4D03F',
  '#4FCDC5',
  '#6CBAF1',
  '#BD91D5',
  '#F2A9A0'
]

export const unitOptions = [
  { label: $t('common.individuals'), value: 'individuals' },
  { label: $t('common.thousands'), value: 'thousands' },
  { label: $t('common.millions'), value: 'millions' },
  { label: $t('common.billions'), value: 'billions' }
]
interface avatarColorType {
  [key: string]: string[]
}

export const avatarColor: avatarColorType = {
  '0': ['#0B3026', '#57B7C7'],
  '1': ['#0C1A72', '#7696AF'],
  '2': ['#0E1B45', '#A793AC'],
  '3': ['#9F0052', '#D29DAD'],
  '4': ['#7D274C', '#B16A70'],
  '5': ['#BE5B1A', '#F1832C'],
  '6': ['#0B3026', '#B1D8D7'],
  '7': ['#0C1A72', '#C6D1D5'],
  '8': ['#0E1B45', '#D7CCD0'],
  '9': ['#9F0052', '#E9CDCC']
}
